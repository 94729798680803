@import '../../style/themes/index';
@import '../../style/mixins/index';

@dropdown-prefix-cls: ~'@{ant-prefix}-dropdown';

.@{dropdown-prefix-cls} {
  &-rtl {
    direction: rtl;
  }

  &::before {
    .@{dropdown-prefix-cls}-rtl& {
      right: -7px;
      left: 0;
    }
  }

  &-menu {
    &-rtl {
      direction: rtl;
      text-align: right;
    }

    &-item-group-title {
      .@{dropdown-prefix-cls}-rtl & {
        direction: rtl;
        text-align: right;
      }
    }

    &-submenu-popup {
      ul,
      li {
        .@{dropdown-prefix-cls}-rtl & {
          text-align: right;
        }
      }
    }

    &-item,
    &-submenu-title {
      .@{dropdown-prefix-cls}-rtl & {
        text-align: right;
      }

      > .anticon:first-child,
      > span > .anticon:first-child {
        .@{dropdown-prefix-cls}-rtl & {
          margin-right: 0;
          margin-left: 8px;
        }
      }

      .@{dropdown-prefix-cls}-menu-submenu-arrow {
        .@{dropdown-prefix-cls}-rtl & {
          right: auto;
          left: @padding-xs;
        }

        &-icon {
          .@{dropdown-prefix-cls}-rtl & {
            margin-left: 0 !important;
            transform: scaleX(-1);
          }
        }
      }
    }

    &-submenu-title {
      .@{dropdown-prefix-cls}-rtl & {
        padding-right: @control-padding-horizontal;
        padding-left: @control-padding-horizontal + @font-size-sm;
      }
    }

    &-submenu-vertical > & {
      .@{dropdown-prefix-cls}-rtl & {
        right: 100%;
        left: 0;
        margin-right: 4px;
        margin-left: 0;
      }
    }
  }
}
