@import '../../style/themes/index';
@import '../../style/mixins/index';

@layout-prefix-cls: ~'@{ant-prefix}-layout';

.@{layout-prefix-cls} {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: @layout-body-background;

  &,
  * {
    box-sizing: border-box;
  }

  &&-has-sider {
    flex-direction: row;
    > .@{layout-prefix-cls},
    > .@{layout-prefix-cls}-content {
      overflow-x: hidden;
    }
  }

  &-header,
  &-footer {
    flex: 0 0 auto;
  }

  &-header {
    height: @layout-header-height;
    padding: @layout-header-padding;
    color: @layout-header-color;
    line-height: @layout-header-height;
    background: @layout-header-background;
  }

  &-footer {
    padding: @layout-footer-padding;
    color: @text-color;
    font-size: @font-size-base;
    background: @layout-footer-background;
  }

  &-content {
    flex: auto;
    /* fix firefox can't set height smaller than content on flex item */
    min-height: 0;
  }

  &-sider {
    position: relative;

    /* fix firefox can't set width smaller than content on flex item */
    min-width: 0;
    background: @layout-sider-background;
    transition: all 0.2s;

    &-children {
      height: 100%;
      margin-top: -0.1px;
      // Hack for fixing margin collaspe bug
      // https://github.com/ant-design/ant-design/issues/7967
      // solution from https://stackoverflow.com/a/33132624/3040605
      padding-top: 0.1px;
    }

    &-has-trigger {
      padding-bottom: @layout-trigger-height;
    }

    &-right {
      order: 1;
    }

    &-trigger {
      position: fixed;
      bottom: 0;
      z-index: 1;
      height: @layout-trigger-height;
      color: @layout-trigger-color;
      line-height: @layout-trigger-height;
      text-align: center;
      background: @layout-trigger-background;
      cursor: pointer;
      transition: all 0.2s;
    }

    &-zero-width {
      & > * {
        overflow: hidden;
      }

      &-trigger {
        position: absolute;
        top: @layout-header-height;
        right: -@layout-zero-trigger-width;
        z-index: 1;
        width: @layout-zero-trigger-width;
        height: @layout-zero-trigger-height;
        color: @layout-trigger-color;
        font-size: @layout-zero-trigger-width / 2;
        line-height: @layout-zero-trigger-height;
        text-align: center;
        background: @layout-sider-background;
        border-radius: 0 @border-radius-base @border-radius-base 0;
        cursor: pointer;
        transition: background 0.3s ease;

        &:hover {
          background: tint(@layout-sider-background, 10%);
        }

        &-right {
          left: -@layout-zero-trigger-width;
          border-radius: @border-radius-base 0 0 @border-radius-base;
        }
      }
    }
  }
}

@import './light';
@import './rtl';
